export const  EVENT_STATUS =  {
    draft : "draft",
    "ne pritje" : "waiting_for_confirmation",
    "konfirmuar" : "confirmed",
    "perfunduar" : "completed",
    "anulluar" : "canceled"
  }

  export const CITIES = [
    "Bajram Curri",
    "Bajzë",
    "Ballsh",
    "Berat",
    "Bilisht",
    "Bulqizë",
    "Burrel",
    "Cërrik",
    "Çorovodë",
    "Delvinë",
    "Divjakë",
    "Durrës",
    "Elbasan",
    "Ersekë",
    "Fier",
    "Fierzë",
    "Finiq",
    "Fushë-Arrëz",
    "Fushë-Krujë",
    "Gjirokastër",
    "Gramsh",
    "Himarë",
    "Kamëz",
    "Kavajë",
    "Këlcyrë",
    "Klos",
    "Konispol",
    "Koplik",
    "Korçë",
    "Krastë",
    "Krrabë",
    "Krujë",
    "Krumë",
    "Kuçovë",
    "Kukës",
    "Kurbnesh",
    "Laç",
    "Leskovik",
    "Lezhë",
    "Libohovë",
    "Librazhd",
    "Lushnjë",
    "Maliq",
    "Mamurras",
    "Manëz",
    "Memaliaj",
    "Milot",
    "Orikum",
    "Patos",
    "Peqin",
    "Përmet",
    "Peshkopi",
    "Pogradec",
    "Poliçan",
    "Prrenjas",
    "Pukë",
    "Reps",
    "Roskovec",
    "Rrëshen",
    "Rrogozhinë",
    "Rubik",
    "Sarandë",
    "Selenicë",
    "Shëngjin",
    "Shijak",
    "Shkodër",
    "Sukth",
    "Tepelenë",
    "Tirana",
    "Ulëz",
    "Ura Vajgurore",
    "Vau i Dejës",
    "Vlorë",
    "Vorë",
  ];
  
